@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply w-32  border-0 cursor-pointer hover:scale-105  bg-blue-700 hover:bg-blue-800 text-white  rounded-md px-2 py-4 ease-in-out duration-700;
  }
}
body {
  font-family: 'Montserrat', sans-serif;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.slide-in-right {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.slide-in-bottom {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.slide-out-left, .slide-out-right, .slide-out-bottom {
  opacity: 0;
}


.slide-border::after {
  @apply block bg-transparent h-1 mt-1 mx-auto transition-all ease-out duration-500;
  content: '';
  width: 0;
}

.slide-border:hover::after {
  @apply bg-blue-300; /* Tailwind's blue-600 color */
  width: 100%;
}
